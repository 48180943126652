<template>
  <div class="bg-black bg-opacity-5 pb-20 md:pb-0">
    <div class="container py-6 md:py-12">
      <div class="grid-container">
        <div class="col-span-12 mb-5">
          <div class="w-[100] h-[30]">
            <img
              src="/A_Logo.svg"
              alt="Altinget logo"
              class="transition-all duration-300"
            />
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 xl:col-span-5">
          <div class="list-title-s !font-light">
            <div class="list-title-xxs !font-medium mb-3">
              {{ $t('InCities') }}
            </div>
            <div class="font-bold">
              {{ $t('PoliticsHaveNeverBeenMoreImportant') }}
            </div>

            <!-- Vi dækker de store linjer i dansk og europæisk politik og går i dybden med dansk politik på vores nichemedier.-->
          </div>
          <div class="flex flex-col md:flex-row mt-4 md:mt-10">
            <template v-if="false">
              <div class="flex flex-col w-full md:w-1/2 mb-8 md:mb-0">
                <span class="mb-1 list-title-4xs">Adresse</span>
                <span class="mb-1 body-s">Ny Kongensgade 10</span>
                <span class="mb-1 body-s">1472 København K</span>
                <span class="mb-1 body-s"
                  >Tlf. <a href="tel:+4533343540">33 34 35 40</a></span
                >
                <a class="mb-6 body-s" href="mailto:redaktionen@altinget.dk"
                  >redaktionen@altinget.dk</a
                >
                <span class="mb-1 body-s">CVR nr.: 29624453</span>
                <span class="body-s">ISSN: 2597-0127</span>
              </div>
              <div class="flex flex-col text-[13px] w-full md:w-1/2">
                <span class="mb-1 list-title-4xs">Ansv. chefredaktør</span>
                <span class="mb-3 body-s">Jakob Nielsen</span>
                <span class="mb-1 list-title-4xs">Direktør</span>
                <span class="mb-3 body-s">Anne Marie Kindberg</span>

                <span class="mb-1 list-title-4xs">CFO</span>
                <span class="mb-3 body-s">Anders Jørning</span>

                <span class="mb-1 list-title-4xs">Kommerciel direktør</span>
                <span class="mb-3 body-s">Michael Thomsen</span>

                <span class="mb-1 list-title-4xs">Formand og udgiver</span>
                <span class="body-s">Rasmus Nielsen</span>
              </div>
            </template>
            <template v-else-if="false">
              <div class="flex flex-col w-full md:w-1/2 mb-8 md:mb-0">
                <span class="mb-1 list-title-4xs">Adress</span>
                <span class="mb-1 body-s">Johannesgränd 1</span>
                <span class="mb-6 body-s">111 30 Stockholm</span>
                <span class="mb-1 list-title-4xs">Redaktionen</span>
                <span class="mb-1 body-s"
                  ><a href="tel:+46(0)812131424">+46 (0)8 12 13 14 24</a></span
                >
                <a class="mb-6 body-s" href="mailto:red@altinget.se"
                  >red@altinget.se</a
                >
                <span class="mb-1 list-title-4xs">Prenumerationsärenden</span>
                <a class="mb-1 body-s" href="tel:+46(0)735299909"
                  >+46 (0) 73 529 99 09</a
                >
                <a class="mb-6 body-s" href="mailto:adm@altinget.se"
                  >adm@altinget.se</a
                >
                <span class="body-s">Org.nr. 556980-5269</span>
              </div>
              <div class="flex flex-col w-full md:w-1/2">
                <span class="mb-1 list-title-4xs"
                  >Chefredaktör och ansvarig utgivare:</span
                >
                <span class="mb-3 body-s">Sanna Rayman</span>
                <span class="mb-1 list-title-4xs">CFO</span>
                <span class="mb-3 body-s">Anders Jørning</span>
                <span class="mb-1 list-title-4xs">Kommersiell direktör</span>
                <span class="mb-3 body-s">Lars Grafström</span>
                <span class="mb-1 list-title-4xs">Vd</span>
                <span class="mb-3 body-s">Anne Marie Kindberg</span>
                <span class="mb-1 list-title-4xs">Ordförande och utgivare</span>
                <span class="body-s">Rasmus Nielsen</span>
              </div>
            </template>
            <template v-else-if="true">
              <div class="flex flex-col w-full md:w-1/2 mb-8 md:mb-0">
                <span class="mb-1 list-title-4xs">Adresse</span>
                <span class="mb-1 body-s">Akersgata 32</span>
                <span class="mb-6 body-s">0180 Oslo</span>
                <span class="mb-1 list-title-4xs">Besøksadresse</span>
                <span class="mb-1 body-s">Grensen 15</span>
                <span class="mb-6 body-s">0180 Oslo</span>
                <span class="mb-6 body-s">Org.nr. 928934977</span>
                <a class="mb-1 body-s" href="mailto:red@altinget.no"
                  >red@altinget.no</a
                >
                <a href="https://www.nored.no/Redaktoeransvar" target="_blank"
                  ><img
                    src="https://legacy.altinget.no/images/nr-ansvarsmerke-svart.png"
                    class="w-28 md:w-1/3 pt-2"
                /></a>
              </div>
              <div class="flex flex-col w-full md:w-1/2">
                <span class="mb-1 list-title-4xs"
                  >Sjefredaktør og ansvarlig utgiver:</span
                >
                <span class="mb-3 body-s">Veslemøy Østrem</span>
                <span class="mb-1 list-title-4xs">CFO</span>
                <span class="mb-3 body-s">Anders Jørning</span>
                <span class="mb-1 list-title-4xs">Kommersiell direktør:</span>
                <span class="mb-3 body-s">Marius Zachariasen</span>
                <span class="mb-1 list-title-4xs"
                  >Administrerende direktør</span
                >
                <span class="mb-3 body-s">Anne Marie Kindberg</span>
                <span class="mb-1 list-title-4xs">Styreleder og utgiver</span>
                <span class="body-s">Rasmus Nielsen</span>
              </div>
            </template>
          </div>
        </div>
        <div
          class="col-start-1 md:col-start-8 col-span-12 md:col-span-6 mt-5 md:mt-0"
        >
          <div class="mt-5 md:mt-1 w-full">
            <ul v-if="indexStore.menu" class="list-title-xxxs flex flex-wrap">
              <li
                v-for="(item, index) in indexStore.menu.footer"
                :key="index"
                class="mb-5 align-top w-full lg:w-1/2 xl:w-1/3"
              >
                <div class="mb-5">
                  {{ item.headline }}
                </div>
                <ul>
                  <li
                    v-for="(subitem, subItemIndex) in item.submenu"
                    :key="'footersubmenu' + subItemIndex"
                  >
                    <NuxtLink
                      v-if="subitem.link.indexOf('https') == -1"
                      :to="subitem.link"
                      class="mb-3 !font-normal font-serif inline-block"
                    >
                      {{ subitem.headline }}
                    </NuxtLink>
                    <a
                      v-else
                      :href="subitem.link"
                      class="mb-3 !font-normal font-serif inline-block"
                    >
                      {{ subitem.headline }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="col-span-6 md:col-span-12 body-s opacity-50 mt-8 flex justify-between"
      >
        Copyright © {{ siteConfig.name }},
        {{ new Date().getFullYear() }}
        <a href="http://alrow.media/">
          <NuxtImg src="/alrow.svg" width="100" class="mb-4" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const siteConfig = useSiteConfig()

const indexStore = useIndexStore()
</script>
